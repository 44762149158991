import axios from 'axios';

const api = axios.create({
baseURL: `https://api.izichat.com.br/`,
//  baseURL: `http://localhost:8000/`,
});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('token_izichat_xpto');
  if (token) { config.headers.Authorization = token }
  return config
},
  (error) => {
    return Promise.reject(error)
  });

const responseSuccessHandler = (response) => {
  return response
}

const responseErrorHandler = async (error) => {
  const originalConfig = error.config;

  if (error.response?.status === 401) {
    try {
      await localStorage.setItem('authenticated_izichat', false);
      await localStorage.setItem('token_izichat_xpto', null);
      window.location.href = '/';

      return api(originalConfig)
    } catch (_error) {
      if (_error.response && _error.response.data) {
        return Promise.reject(_error.response.data)
      }

      return Promise.reject(_error)
    }
  }

  return Promise.reject(error)
}

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export default api;
